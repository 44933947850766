<template>
  <div>
<!--    <div class="block-title">-->
<!--      <div class="fl"><i class="el-icon-arrow-left back" @click="goBack"></i>{{ this.id ? '员工详情' : '新增员工' }}</div>-->
<!--    </div>-->
    <el-form v-loading="formLoading" label-position="left" class="common-form left_10" label-width="100px" style="padding-top: 10px"
             :model="form" :rules="rules" ref="form">
      <el-form-item label="员工编号：" prop="code">
        <el-input class="small-input" v-model="form.code" placeholder="请输入员工编号"></el-input>
      </el-form-item>
      <el-form-item label="真实姓名：" prop="name">
        <el-input class="small-input" v-model="form.name" placeholder="请输入真实姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="phone">
        <el-input class="small-input" v-model="form.phone" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="员工状态：" prop="state">
        <el-radio v-model="form.state" :label="1">有效</el-radio>
        <el-radio v-model="form.state" :label="0">注销</el-radio>
      </el-form-item>
      <el-form-item label="分配角色：" prop="roleId">
        <el-select class="small-input" v-model="form.roleId" placeholder="请选择角色">
          <el-option
              v-for="item in roleList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="btn-list">
      <el-button type="primary"  style="padding: 12px 50px" @click="submit" :loading="loading">保 存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddStaff",
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      form: {
        code: "",
        name: "",
        phone: "",
        roleId: "",
        state:1,
      },
      rules: {
        name: [
          {required: true, message: '请输入真实姓名', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入员工编号', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
        ],
        state:[
          {required: true, message: '请选择员工状态', trigger: 'change'},
        ],
        roleId: [
          {required: true, message: '请选择角色', trigger: 'change'},
        ]
      },
      roleList: [],
      loading: false,
      formLoading: false,
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    getRoleList() {
      this.$axios.get("/sys-api/role-api/roleList", {}, (res) => {
        if (res.code === '100') {
          this.roleList = res.data;
        }
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let formData = {...this.form};
          if (!this.id) {
            this.$axios.post("/sys-api/employee-api/insertEmployee", formData, (res) => {
              this.loading = false;
              if (res.code === '100') {
                this.$message.success("新增员工成功");
                this.$router.push({name:"StaffList"})
              }
            })
          } else {
            formData.id = this.id;
            this.$axios.post("/sys-api/employee-api/updateEmployee", formData, (res) => {
              this.loading = false;
              if (res.code === '100') {
                this.$message.success("更新员工信息成功");
                this.$router.push({name:"StaffList"})
              }
            })
          }

        }
      })
    }
  },
  mounted() {
    this.getRoleList();
    if (this.id) {
      this.formLoading = true;
      this.$axios.get('/sys-api/employee-api/getEmployeeById', {id: this.id}, (res) => {
        this.formLoading = false;
        if (res.code === '100') {
          this.form.name = res.data.name;
          this.form.code = res.data.code;
          this.form.phone = res.data.phone;
          this.form.roleId = res.data.roleId;
          this.form.state = res.data.state;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.small-input {
  width: 300px;
}
.btn-list{
  width: 400px;
  text-align: center;
  margin-top: 50px;
}
</style>
